import { getCurrencyInfo } from "@lib/api/layout";
import axios from "axios";
import { checkCookies, getCookie, setCookies } from "cookies-next";
import moment, { Moment } from "moment";
import "moment/min/locales";
import * as locales from "date-fns/locale";
import { animateScroll as scroll } from "react-scroll";
import { getheadersGeneral } from "@lib/helpers";
import { getDomainConfigBackend } from "./config";
import { getCurrencySimbol } from "./helperHotelRoom";
import { scroller } from "react-scroll";
import { MAPID } from "@helpers/config";
import { defaultNotFound } from "@data/imagesNotFound";

const KEY_PRODUCT_ALLOWED = "productsAllowed";
const COUNTRIES_LIST = "countriesList";

export const CERTIFICATE_ID = 116;
export const CASH_CARD_ID = 114;
export const DISCOUNT_CARD_ID = 115;

export const EXPRESSION_NAME = /^[a-zA-Z]+$/;
export const EXPRESSION_MAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const EXPRESSION_NUMBER = /^[0-9]+$/;
export const WITH_DECIMALS = true;
export const EXPRESSION_FIRST_LAST_NAME = /^[a-zA-Z\s]*$/;
export const validateData = (val: string, expression: RegExp) => {
  return val.match(RegExp(expression)) ? val : val?.slice(0, val.length - 1);
};

const germanDays = ["Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa.", "So."];
const germanMonths = [
  "Jan.",
  "Feb.",
  "Mär.",
  "Apr.",
  "Mai",
  "Jun.",
  "Jul.",
  "Aug.",
  "Sep.",
  "Okt.",
  "Nov.",
  "Dez.",
];

const isLocalDateDetuch = (dateString: string) => {
  const trimedDate = dateString.trim();
  const dayMatch = trimedDate.match(/^[A-Za-z]{2,3}\./);
  const monthMatch = trimedDate.match(/[A-Za-z]{3}\./);

  if (dayMatch && monthMatch) {
    const dayAbbr = dayMatch[0]; // Día abreviado
    const monthAbbr = monthMatch[0]; // Mes abreviado

    if (germanDays.includes(dayAbbr) && germanMonths.includes(monthAbbr)) {
      // German Days av
      const dayMap: any = {
        Mo: "Mon.",
        Di: "Tue.",
        Mi: "Wed.",
        Do: "Thu.",
        Fr: "Fri.",
        Sa: "Sat.",
        So: "Sun.",
      };

      // German Months
      const monthMap: any = {
        Jan: "Jan.",
        Feb: "Feb.",
        Mär: "Mar.",
        Apr: "Apr.",
        Mai: "May",
        Jun: "Jun.",
        Jul: "Jul.",
        Aug: "Aug.",
        Sep: "Sep.",
        Okt: "Oct.",
        Nov: "Nov.",
        Dez: "Dec.",
      };

      // Regex to get date specific sections
      const regex =
        /^([A-Za-z]{2,3})\.,\s([A-Za-z]{3,4})\.\s(\d{1,2})\s(\d{2})$/;

      // remove space from string
      const match = trimedDate.match(regex);

      if (match) {
        const day: string = match[1]; // Día de la semana ( "Fr.")
        const month: string = match[2]; // Mes ( "Dez.")
        const dayNum: string = match[3]; // Día del mes ( "13")
        const year: string = match[4]; // Año ( "24")

        // Map to convert day deutch to English
        const englishDay = dayMap[day];
        const englishMonth = monthMap[month];

        if (
          `${englishDay}, ${englishMonth} ${dayNum} ${year}`.includes(
            "undefined"
          )
        ) {
          return trimedDate;
        }
        // return format on English
        return `${englishDay}, ${englishMonth} ${dayNum} ${year}`;
      }
    } else {
      // returns input value if date is not in German
      return trimedDate;
    }
  } else {
    // eturns input value if date is not in German
    return trimedDate;
  }
};

//aquiRevisarA
export const getDatesFromRange = (dateRange: string) => {
  const dates = dateRange?.split("-");
  //console.info("getDatesFromRange", dates);
  try {
    let tmpDay1 = isLocalDateDetuch(dates[0]);
    let tmpDay2 = isLocalDateDetuch(dates[1]);
    const day = moment(tmpDay1 ?? dates[0], "ddd, MMM DD YY");
    const day2 = moment(tmpDay2 ?? dates[1], "ddd, MMM DD YY");
    const diff = day2.diff(day, "days");
    const date1 = day.format("YYYY-MM-DD");
    const date2 = day2.format("YYYY-MM-DD");
    return {
      date1,
      date2,
      diff,
    };
  } catch (error) {
    console.info("getDatesFromRange error", error);
    const day = moment();
    const day2 = moment();
    const diff = day2.diff(day, "days");
    const date1 = day.format("YYYY-MM-DD");
    const date2 = day2.format("YYYY-MM-DD");
    return {
      date1,
      date2,
      diff,
    };
  }
};
export const getDatesFromRangeWithLocale = (
  dateRange: string,
  localeFrom: string,
  localeTo: string,
  format = "ddd, MMM DD YY"
) => {
  const dates = dateRange?.split("-");
  //console.info("getDatesFromRange", dates);
  try {
    const day = moment(dates[0], format, localeFrom).locale(localeTo);
    const day2 = moment(dates[1], format, localeFrom).locale(localeTo);
    const diff = day2.diff(day, "days");
    const date1 = day.format("YYYY-MM-DD");
    const date2 = day2.format("YYYY-MM-DD");
    return {
      date1,
      date2,
      diff,
    };
  } catch (error) {
    console.info("getDatesFromRange error", error);
    const day = moment();
    const day2 = moment();
    const diff = day2.diff(day, "days");
    const date1 = day.format("YYYY-MM-DD");
    const date2 = day2.format("YYYY-MM-DD");
    return {
      date1,
      date2,
      diff,
    };
  }
};

export const paginator = (
  items: any[],
  current_page = 1,
  per_page_items = 10
) => {
  let page = current_page || 1,
    per_page = per_page_items || 10,
    offset = (page - 1) * per_page,
    paginatedItems = items?.slice(offset).slice(0, per_page_items),
    total_pages = Math.ceil(items?.length / per_page);

  return {
    page: page,
    per_page: per_page,
    pre_page: page - 1 ? page - 1 : null,
    next_page: total_pages > page ? page + 1 : null,
    total: items?.length,
    total_pages: total_pages,
    data: paginatedItems,
  };
};

export const getRoomsFromPaxString = (paxConfig: GuestConfigurationType[]) => {
  //console.log("getRoomsFromPaxString >>", paxConfig);
  let pax = [
    { room: 1, adult: 2, children: 0, child: [] },
  ] as GuestConfigurationType[];
  if (Array.isArray(paxConfig)) {
    pax = [...paxConfig];
  }
  const rooms = pax?.map((room, _index, childRoom: any) => {
    childRoom = room.child;
    return { adult_count: room.adult, childs: childRoom };
  });
  return rooms;
};

export const getRoomsFromPaxStringSummary = (
  paxConfig: GuestConfigurationType[]
) => {
  let rooms = 0;
  let adults = 0;
  let children = 0;
  if (Array.isArray(paxConfig)) {
    paxConfig?.map((p) => {
      rooms = rooms + 1;
      adults = adults + p.adult;
      children = children + p.children;
    });
  }

  return { rooms, adults, children };
};

export const replaceByValue = (
  json: any,
  field: string,
  newvalue: any,
  oldvalue: any
) => {
  for (var k = 0; k < json.length; ++k) {
    if (oldvalue) {
      if (oldvalue == json[k][field]) {
        json[k][field] = newvalue;
      }
    } else {
      json[k][field] = newvalue;
    }
  }
  return json;
};

export const resetValuesForRadio = (json: any, newvalue: boolean) => {
  for (const key in json) {
    if (Object.prototype.hasOwnProperty.call(json, key)) {
      json[key] = newvalue;
    }
  }
  return json;
};

export const getImageName = (name: string) => {
  try {
    return `${name
      .replace(/[0-9]/g, "")
      .split(" ")
      .join("-")
      .toLowerCase()
      .replace(/^-+/, "")
      .replace(/:\s*/g, "")
      .replace(/\(|\)/g, "")}`;
  } catch (error) {
    console.info(error);

    return "";
  }
};

export const getCustomerReviewLabel = (customerRating: number) => {
  if (customerRating <= 5) {
    if (customerRating >= 4) {
      return "EXCELLENT";
    }
    if (customerRating >= 3) {
      return "VERY GOOD";
    }
    return "GOOD";
  } else {
    if (customerRating > 5) {
      return "EXCELLENT";
    }
    return "GOOD";
  }
};

export const getCustomerReviewLabelGeneral = (rate: number) => {
  let rating = Math.round(rate);

  if (rating > 5) {
    return "EXCELLENT";
  }

  switch (rating) {
    case 5:
      return "reviewscore5";
      break;
    case 4:
      return "reviewscore4";
      break;
    case 3:
      return "reviewscore3";
      break;
    case 2:
      return "reviewscore2";
      break;
    case 1:
      return "reviewscore1";
      break;

    default:
      return "reviewscore1";
  }
};
export const formatNumber = (val: number | string, fix = 2) => {
  return Number(val).toFixed(fix);
};

export const getCurrentLanguage = async () => {
  if (checkCookies("lan_id")) {
    return {
      lan_id: getCookie("lan_id"),
      lang_code: getCookie("lang_code"),
      lan_name: getCookie("lan_name"),
      currency_id: getCookie("currency_id"),
      currency_code: getCookie("currency_code"),
      currency_name: getCookie("currency_name"),
    };
  }

  try {
    if (typeof window == "undefined" || !localStorage) {
      return;
    }
    const data = await getCurrencyInfo("en-US");

    // Set all coockies
    if (!localStorage.getItem("currencies")) {
      localStorage.setItem(
        "currencies",
        JSON.stringify(data?.currencylanguageinfoConfig)
      );
      return;
    }

    return {
      lan_id: data?.currencylanguageinfoConfig.languages[0]?.defaultLang
        ? data?.currencylanguageinfoConfig.languages[0]?.defaultLang[0][0]
            ?.lang_id
        : 1,
      lang_code: data?.currencylanguageinfoConfig.languages[0]?.defaultLang
        ? data?.currencylanguageinfoConfig.languages[0]?.defaultLang[0][0]
            ?.lan_code
        : "en-US",
      lan_name: data?.currencylanguageinfoConfig.languages[0]?.defaultLang
        ? data?.currencylanguageinfoConfig?.languages[0]?.defaultLang[0][0]
            ?.lan_name
        : "English",
      currency_id: data?.currencylanguageinfoConfig?.currency[0]
        ?.defaultCurrency
        ? data?.currencylanguageinfoConfig?.currency[0]?.defaultCurrency[0][0]
            ?.currency_id
        : 9,
      currency_code: data?.currencylanguageinfoConfig?.currency[0]
        ?.defaultCurrency
        ? data?.currencylanguageinfoConfig?.currency[0]?.defaultCurrency[0][0]
            ?.currency_code
        : "USD",
      currency_name: data?.currencylanguageinfoConfig?.currency[0]
        ?.defaultCurrency
        ? data?.currencylanguageinfoConfig?.currency[0]?.defaultCurrency[0][0]
            ?.currency_name
        : "Dollar",
    };
  } catch (err) {
    console.error(err);
    return {
      lan_id: null,
      lang_code: null,
      lan_name: null,
      currency_id: null,
      currency_code: null,
      currency_name: null,
    };
  }
};

export const initCookiesFromServer = async (req: any, res: any) => {
  if (checkCookies("lan_id", { req, res })) {
    return {
      lan_id: getCookie("lan_id", { req, res }),
      lang_code: getCookie("lan_code", { req, res }),
      lan_name: getCookie("lan_name", { req, res }),
      currency_id: getCookie("currency_id", { req, res }),
      currency_code: getCookie("currency_code", { req, res }),
      currency_name: getCookie("currency_name", { req, res }),
    };
  }

  try {
    const config = getDomainConfigBackend(req);
    const url = `${config?.backendPath}/hotels/gethomecurrencyinfo`;
    const response = await axios.get(url, {
      headers: {
        ...getheadersGeneral(req, res),
      },
    });
    const data = response.data.data;

    if (data?.languages[0]?.defaultLang) {
      setCookies("lan_id", data?.languages[0]?.defaultLang[0][0]?.lang_id, {
        req,
        res,
      });
      setCookies("lan_code", data?.languages[0]?.defaultLang[0][0]?.lan_code, {
        req,
        res,
      });
      setCookies("lan_name", data?.languages[0]?.defaultLang[0][0]?.lan_name, {
        req,
        res,
      });
    } else {
      setCookies("lan_id", 1);
      setCookies("lan_code", "en-US");
      setCookies("lan_name", "English");
    }

    if (data?.currency[0]?.defaultCurrency) {
      setCookies(
        "currency_id",
        data?.currency[0]?.defaultCurrency[0][0]?.currency_id,
        { req, res }
      );
      setCookies(
        "currency_code",
        data?.currency[0]?.defaultCurrency[0][0]?.currency_code,
        { req, res }
      );
      setCookies(
        "currency_name",
        data?.currency[0]?.defaultCurrency[0][0]?.currency_name,
        { req, res }
      );
    } else {
      setCookies("currency_id", 9);
      setCookies("currency_code", "USD");
      setCookies("currency_name", "Dollar");
    }

    const realLangId = data?.languages[0]?.defaultLang
      ? data?.languages[0]?.defaultLang[0][0]?.lang_id
      : data?.languages[0]?.langDetails
      ? data?.languages[0]?.langDetails[0].lang_id
      : "1";
    const realLangCode = data?.languages[0]?.defaultLang
      ? data?.languages[0]?.defaultLang[0][0]?.lan_code
      : data?.languages[0]?.langDetails
      ? data?.languages[0]?.langDetails[0].code
      : "en-US";
    const realLangName = data?.languages[0]?.defaultLang
      ? data?.languages[0]?.defaultLang[0][0]?.lan_name
      : data?.languages[0]?.langDetails
      ? data?.languages[0]?.langDetails[0].name
      : "English";

    const realCurrencyId = data?.currency[0]?.defaultCurrency
      ? data?.currency[0]?.defaultCurrency[0][0]?.currency_id
      : data?.currency[0]?.currencyDetails
      ? data?.currency[0]?.currencyDetails[0].id
      : "9";
    const realCurrencyCode = data?.currency[0]?.defaultCurrency
      ? data?.currency[0]?.defaultCurrency[0][0]?.currency_code
      : data?.currency[0]?.currencyDetails
      ? data?.currency[0]?.currencyDetails[0].code
      : "USD";
    const realCurrencyName = data?.currency[0]?.defaultCurrency
      ? data?.currency[0]?.defaultCurrency[0][0]?.currency_name
      : data?.currency[0]?.currencyDetails
      ? data?.currency[0]?.currencyDetails[0].name
      : "Dollar";
    return {
      lan_id: realLangId,
      lang_code: realLangCode,
      lan_name: realLangName,
      currency_id: realCurrencyId,
      currency_code: realCurrencyCode,
      currency_name: realCurrencyName,
    };
    // return {
    //   lan_id: data?.languages[0]?.defaultLang[0][0]?.lang_id,
    //   lang_code: data?.languages[0]?.defaultLang[0][0]?.lan_code,
    //   lan_name: data?.languages[0]?.defaultLang[0][0]?.lan_name,
    //   currency_id: data?.currency[0]?.defaultCurrency[0][0]?.currency_id,
    //   currency_code: data?.currency[0]?.defaultCurrency[0][0]?.currency_code,
    //   currency_name: data?.currency[0]?.defaultCurrency[0][0]?.currency_name,
    // };
  } catch (error: any) {
    console.info(error?.response?.data);
    return {
      lan_id: null,
      lang_code: null,
      lan_name: null,
      currency_id: null,
      currency_code: null,
      currency_name: null,
    };
  }
};

export const routeDictionary = [
  { key: "/", value: "Home_Page" },
  { key: "/login/sso", value: "SSO" },
  { key: "/search-detail", value: "SRP" },
  { key: "/hotel-details", value: "HDP" },
  { key: "/hotel-checkout", value: "Checkout_Page" },
  { key: "/flight", value: "Home_Page" },
  { key: "/flight/search-details", value: "Flight_SRP" },
  { key: "/flight/checkout", value: "Flight_Checkout_Page" },
  { key: "/flight/checkout-success", value: "Flight_Checkout_Success" },
  { key: "/wallet", value: "Wallet_Homepage" },
  { key: "/user-details", value: "My_Account" },
  { key: "/wallet-checkout", value: "Wallet_Checkout" },
  { key: "/cruises", value: "Home_Page" },
  { key: "/holidaystays", value: "Home_Page" },
  { key: "/holidaystays/search-detail", value: "SRP" },
  { key: "/cars", value: "Home_Page" },
  { key: "/cars/search-details", value: "SRP" },
  { key: "/cars/details", value: "HDP" },
  { key: "/cars/checkout", value: "Checkout_Page" },
  { key: "/cars/checkout-success", value: "Checkout_Page" },
  { key: "/weeks", value: "Home_Page" },

  { key: "/transfers", value: "Transfers_Home_Page" },
  { key: "/transfers/search-details", value: "Transfers_SRP" },
  { key: "/transfers/details", value: "Transfers_HDP" },
  { key: "/transfers/checkout", value: "Transfers_Checkout_Page" },
  { key: "/transfers/checkout-success", value: "Transfers_Checkout_Success" },

  { key: "/activities", value: "Activities_Home_Page" },
  { key: "/activities/search-detaila", value: "Activities_SRP" },
  { key: "/activities/details", value: "Activities_HDP" },
  { key: "/activities/checkout", value: "Activities_Checkout_Page" },
  { key: "/activities/checkout-success", value: "Activities_Checkout_Success" },
];

export const getCurrentLocale = () => {
  if (checkCookies("lan_code")) {
    const lanCode = String(getCookie("lan_code"));
    const currentLocale = lanCode.replace("-", "");
    const localeSplit = lanCode.split("-")[0];
    const aux: any = locales;

    try {
      return aux[String(currentLocale)] ? aux[currentLocale] : aux[localeSplit];
    } catch (error) {
      return aux?.["enUS"];
    }
  }
};

export const goToTop = () => {
  scroll.scrollToTop();
};

export const isImgFromUrl = (imgName: string) => {
  return imgName.substring(0, 4) === "http";
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const convertMsToTime = (milliseconds: number, showHours = false) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  if (showHours) {
    hours = hours % 24;
    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
      seconds
    )}`;
  }
  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export const getDateRange = (date1: Date | Moment, date2: Date | Moment) => {
  const day = moment(date1, "YYYY-MM-DD");
  const day2 = moment(date2, "YYYY-MM-DD");

  const rangeDate = `${day.format("ddd, MMM DD YY")} - ${day2.format(
    "ddd, MMM DD YY"
  )}`;
  return rangeDate;
};

export const getDateSearch = (date1: Date | Moment) => {
  const day = moment(date1, "YYYY-MM-DD");

  const rangeDate = day.format("ddd, MMM DD YY");
  return rangeDate;
};
export const getDateFormat = (date: Date | Moment, format = "YYYY-MM-DD") => {
  const dateToFormat = moment(date);
  dateToFormat.locale(getCurrentLocale()?.code || "en");
  return dateToFormat.format(format);
};

export const getDateFlightFormat = (
  date1: Date | Moment,
  dateFormat: string
) => {
  const day = moment(date1, "YYYY-MM-DD");

  const rangeDate = day.format(dateFormat || "DD/MM");
  return rangeDate;
};

export const urlQueryToJson = (url: string) => {
  const urlParams = new URLSearchParams(url);

  const entries: any = urlParams.entries();
  const result: any = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }

  return result;
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

// Guest Configuration // Counter
export const getGuestCount = (
  data: GuestConfigurationType[],
  key: "adult" | "children" | "room"
) => {
  if (key == "room") {
    return data.length;
  }
  const result = data.reduce(function (
    prev: number,
    current: GuestConfigurationType
  ) {
    return prev + current[key];
  },
  0);
  return result;
};

export const filterByText = (data: any[], searchText: string, key?: string) => {
  try {
    var search = new RegExp(searchText, "ig"); // prepare a regex object
    if (key) {
      const b = data.filter((item) => search.test(item[key]));
      return b;
    }
    const b = data.filter((item) => search.test(item));
    return b;
  } catch (error) {
    console.info("filterByText", error);
    return [];
  }
};

export const existsInArray = (
  data: any[],
  searchText: string,
  key?: string
) => {
  try {
    var search = new RegExp(searchText, "ig"); // prepare a regex object
    if (key) {
      const b = data?.some((item) => search.test(item[key]));
      return b;
    }
    const b = data?.some((item) => search.test(item));
    return b;
  } catch (error) {
    console.info("existsInArray", error);
    return false;
  }
};

export const excludeInArray = (data: any[], exclude: any[], key?: string) => {
  try {
    if (key) {
      const b = data.filter(
        (item) =>
          !exclude.some((e) => {
            const search = new RegExp(e, "ig"); // prepare a regex object
            return search.test(item[key]);
          })
      );
      return b;
    }
    const b = data.filter(
      (item) =>
        !exclude.some((e) => {
          const search = new RegExp(e, "ig"); // prepare a regex object
          return search.test(item);
        })
    );
    return b;
  } catch (error) {
    //console.info("excludeInArray", error);
    return [];
  }
};

export const getProducts = () => {
  if (typeof window == "undefined" || !localStorage) {
    return [];
  }
  if (!localStorage.getItem(KEY_PRODUCT_ALLOWED)) {
    return [];
  } else {
    const productsList: ProductsApiType[] = JSON.parse(
      String(localStorage.getItem(KEY_PRODUCT_ALLOWED) || "[]")
    );
    return productsList;
  }
};

export const insertProducts = (products: ProductsApiType[]) => {
  if (typeof window == "undefined" || !localStorage) {
    return [];
  }
  localStorage.setItem(KEY_PRODUCT_ALLOWED, JSON.stringify(products));
  return products;
};

export const findCenterCoordinates = (markers: CoordinatesType[]) => {
  let lat = 0;
  let lng = 0;

  for (let i = 0; i < markers.length; ++i) {
    lat += markers[i].lat;
    lng += markers[i].lng;
  }

  lat /= markers.length;
  lng /= markers.length;

  return { lat: lat, lng: lng };
};

export const timeConvertFromMinutes = (min?: number) => {
  if (min) {
    const hours = min / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return {
      hours: rhours,
      minutes: rminutes,
    };
  }
  return {
    hours: 0,
    minutes: 0,
  };
};

export const getDateToFormat = (
  date: Date | Moment,
  formatString = "YYYY-MM-DD",
  autoLocale = false
) => {
  try {
    const dateToFormat = moment(date);
    if (autoLocale) {
      dateToFormat.locale(getCurrentLocale()?.code || "en");
      // return format(date, formatString, {
      //   locale: getCurrentLocale()?.code || "en",
      // });
    }
    return dateToFormat.local().format(formatString);
  } catch (error) {}
  return "";
  // return format(date, formatString);
};

export const getSymb = (code?: string) => {
  if (code) {
    return getCurrencySimbol(code);
  }
  return getCurrencySimbol(String(getCookie("currency_code") || "USD"));
};

export const capitalize = (str: string, all = true) => {
  try {
    const strAux = str.toLowerCase();
    if (all) {
      const arr = strAux.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }

    return strAux.charAt(0).toUpperCase() + strAux.slice(1);
  } catch (error) {
    console.info("capitalize", error);

    return str;
  }
};
export const getCurrencyCode = () => {
  return checkCookies("currency_code")
    ? String(getCookie("currency_code"))
    : "USD";
};

export const languageCurrencyUserOut = () => {
  setCookies("currency_name", String(getCookie("currency_name_logout")));
  setCookies("currency_code", String(getCookie("currency_code_logout")));
  setCookies("currency_id", String(getCookie("currency_id_logout")));
  setCookies("lan_name", String(getCookie("lan_name_logout")));
  setCookies("lan_code", String(getCookie("lan_code_logout")));
  setCookies("lan_id", String(getCookie("lan_id_logout")));
};

export const jumpToCalendarSearch = () => {
  setTimeout(() => {
    scroller.scrollTo("search-calendar", {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  }, 200);
};

export const isTimeString = (str: string) => {
  const regexp = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/;
  const regexp24 = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
  return regexp.test(str) || regexp24.test(str);
};

export const getLangCode = () => {
  try {
    const lanCode = String(getCookie("lan_code"));
    const code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
    return code;
  } catch (error) {}
  return "en";
};

export const getLangCodeFull = () => {
  try {
    const code = checkCookies("lan_code")
      ? String(getCookie("lan_code"))
      : "en-US";
    return code;
  } catch (error) {}
  return "en-US";
};

export const isInt = (n: any) => {
  return Number(n) === n && n % 1 === 0;
};

export const isFloat = (n: any) => {
  return Number(n) === n && n % 1 !== 0;
};

export const replaceHostVariableInString = (val: string) => {
  const providerLink = val.replace("{{host_url}}", window.location.origin);
  return providerLink;
};

export const setLangCookies = (
  langName: string,
  langCode: string,
  langId: number
) => {
  setCookies("lan_name", langName);
  setCookies("lan_code", langCode);
  setCookies("lan_id", langId);
};
export const setLangCookiesLogout = (
  langName: string,
  langCode: string,
  langId: number
) => {
  setCookies("lan_name_logout", langName);
  setCookies("lan_code_logout", langCode);
  setCookies("lan_id_logout", langId);
};
export const setCurrencyCookies = (id: number, name: string, code: string) => {
  setCookies("currency_id", id);
  setCookies("currency_name", name);
  setCookies("currency_code", code);
};

export const setCurrencyCookiesLogout = (
  id: number,
  name: string,
  code: string
) => {
  setCookies("currency_name_logout", name);
  setCookies("currency_code_logout", code);
  setCookies("currency_id_logout", id);
};

type ArgsSetLangsType = {
  data: LanguageResultType[];
  setLanguage: (setLanguage: any) => void;
  keyWord?: string;
};
export const setWhiteLabelLangData = ({
  data,
  setLanguage,
  keyWord,
}: ArgsSetLangsType) => {
  if (data) {
    switch (keyWord) {
      case "SSO":
        setLanguage({
          SSO: data,
        });
        break;
      case "Home_Page":
        setLanguage({
          home_page: data,
        });
        break;
      case "SRP":
        setLanguage({
          srp: data,
        });
        break;
      case "HDP":
        setLanguage({
          hdp: data,
        });
        break;
      case "Checkout_Page":
        setLanguage({
          checkout_page: data,
        });
        break;
      case "Wallet_Homepage":
        setLanguage({
          wallet_homepage: data,
        });
        break;
      case "My_Account":
        setLanguage({
          my_account: data,
        });
        break;
      case "Wallet_Checkout":
        setLanguage({
          wallet_checkout: data,
        });
        break;
      case "Flight_SRP":
        setLanguage({
          flight_srp: data,
        });
        break;
      case "Flight_Checkout_Page":
        setLanguage({
          flight_srp: data,
        });
        break;
      case "Flight_Checkout_Success":
        setLanguage({
          flight_srp: data,
        });
        break;

      case "Transfers_Home_Page":
        setLanguage({
          transfers: data,
        });
        break;
      case "Transfers_SRP":
        setLanguage({
          transfers: data,
        });
        break;
      case "Transfers_HDP":
        setLanguage({
          transfers: data,
        });
        break;
      case "Transfers_Checkout_Page":
        setLanguage({
          transfers: data,
        });
        break;
      case "Transfers_Checkout_Success":
        setLanguage({
          transfers: data,
        });
        break;

      case "Activities_Home_Page":
        setLanguage({
          activities: data,
        });
        break;
      case "Activities_SRP":
        setLanguage({
          activities: data,
        });
        break;
      case "Activities_HDP":
        setLanguage({
          activities: data,
        });
        break;
      case "Activities_Checkout_Page":
        setLanguage({
          activities: data,
        });
        break;
      case "Activities_Checkout_Success":
        setLanguage({
          activities: data,
        });
        break;
    }
  }
};

export const detectBrowser = (availableBrowsers: string[]) => {
  let browser = "";
  try {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
      browser = "Opera";
    } else if (navigator.userAgent.indexOf("Edg") != -1) {
      browser = "Edge";
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      browser = "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      browser = "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      browser = "Firefox";
    } else if (navigator.userAgent.indexOf("MSIE") != -1) {
      //IF IE > 10
      browser = "IE";
    } else {
      console.log("unknown");
    }
  } catch (error) {
    console.log(`Error detectBrowser()_ `, { error: error });
  }
  return availableBrowsers.includes(browser);
};

export const checkIsMacOrIOS = (includesMac = false) => {
  if (typeof window !== "undefined") {
    const platform = navigator?.platform || navigator?.userAgent || "unknown";

    let isOSX = false;

    if (includesMac) {
      isOSX = /(Mac|iPhone|iPod|iPad)/i.test(platform);
    } else {
      isOSX = /(iPhone|iPod|iPad)/i.test(platform);
    }

    if (isOSX) {
      return true;
    }
  }
  return false;
};

export const jumpToElement = (
  scrollElement: string,
  offset = -50,
  containerId: null | string = null
) => {
  scroller.scrollTo(scrollElement, {
    duration: 1000,
    delay: 0,
    // smooth: true,
    smooth: "easeInOutQuart",
    // containerId: 'ContainerElementID',
    offset, // Scrolls to element + 50 pixels down the page
    containerId,
  });
};
export const optionsMap = () => {
  let optionsMapFinal = {
    fullscreenControl: false,
    zoomControl: true,
    zoomControlOptions: { position: 8 },
    streetViewControlOptions: { position: 8 },
    streetViewControl: true,
    minZoom: 3,
    maxZoom: 20,
  };
  if (MAPID) {
    return { ...optionsMapFinal, mapId: MAPID };
  }
  return optionsMapFinal;
};

export const getIdCountryDefault = (dataCountry: CountryListType[]) => {
  const defCountryCode = checkCookies("userCountryCode")
    ? getCookie("userCountryCode")
    : "US";
  const found = dataCountry.find(
    (f) => f.countryCode == String(defCountryCode)
  );

  return found ? found.countryId : 1;
};

export const getIdCountryFindCountryCode = (
  dataCountry: CountryListType[],
  code: string
) => {
  const countryId = dataCountry?.find(
    (f) => f?.countryCode.toUpperCase() == code.toUpperCase()
  );
  if (countryId) {
    return countryId;
  }
  return undefined;
};

export const getIdNationFindCountryCode = (
  dataNation: NationalityDataType[],
  code: string
) => {
  const countryId = dataNation?.find(
    (f) => f?.nationalityCode.toUpperCase() == code.toUpperCase()
  );
  if (countryId) {
    return countryId;
  }
  return undefined;
};

export const getIdNationDefault = (dataNation: NationalityDataType[]) => {
  let found = null;
  const countryInfoCookie = checkCookies("countryInfo")
    ? JSON.parse(String(getCookie("countryInfo")))
    : null;
  const positionCountryId = countryInfoCookie?.geoplugin_countryCode || null;

  const defNation = checkCookies("userCountryCode")
    ? getCookie("userCountryCode")
    : "US";

  if (positionCountryId) {
    found = dataNation?.find(
      (f) => f.nationalityCode == String(positionCountryId)
    );
  } else {
    found = dataNation?.find((f) => f.nationalityCode == String(defNation));
  }

  return found ? found : undefined;
};

export const checkImageSrc = (src: string) => {
  try {
    if (src?.includes("http") || src?.includes("https")) {
      return src;
    }
  } catch (error) {}
  return defaultNotFound;
};

export const JSONParseOrnNull = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return null;
  }
};

// helper to show currency format - amount format - without decimals

export const preventExecuteCodeHTMLJS = (word: string) => {
  if (
    (word.toLowerCase().includes("select") &&
      word.toLowerCase().includes("from") &&
      word.toLowerCase().includes("where")) ||
    word.toLowerCase().includes("alter table") ||
    word.toLowerCase().includes("drop table") ||
    word.toLowerCase().includes("drop database")
  ) {
    return "";
  }
  return word
    .replace(/</g, "")
    .replace(/>/g, "")
    .replace(/'/g, "")
    .replace(/"/g, "")
    .replace(/\//g, "")
    .replace(/{/g, "")
    .replace(/}/g, "")
    .replace(/\[/g, "")
    .replace(/\]/g, "");
};

export const setFormatDate = (date: string, time: string) => {
  let finalDate = date;
  if (date.includes("/")) {
    let splitDate = finalDate.split("/");
    let y = splitDate[2];
    let m = splitDate[1];
    let d = splitDate[0];
    if (y.length <= 2) {
      y = `20${splitDate[2]}`;
    }
    finalDate = `${d}/${m}/${y}`;
  }

  let finalHour = time;
  let splitHour = time.split(" ");
  if (splitHour[0].length <= 5) {
    finalHour = `${splitHour[0]}:00 ${splitHour[1]}`;
  }
  const formatted = new Date(`12/12/2023 ${finalHour}`);

  return moment(formatted).format("LLLL");
};

export const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const getMathRoundLoadingGift = () => {
  let MathRandom: number = 0;

  try {
    MathRandom = Number(String(localStorage.getItem("randomMath") || "0"));
  } catch (error) {
    console.log(`error: ${error}`);
  }

  if (!MathRandom) {
    MathRandom = Math.random();
    try {
      localStorage.setItem("randomMath", String(MathRandom));
    } catch (error) {
      console.log(`error: ${error}`);
    }
  }
  return MathRandom;
};

export const getCountryCode = () => {
  const defCountryCode = getCookie("userCountryCode");
  if (defCountryCode) return String(defCountryCode);

  if (getCookie("orgCountryCode")) return String(getCookie("orgCountryCode"));

  return "US";
};

export const getHost = () => {
  var protocol = location.protocol;
  var slashes = protocol.concat("//");
  var host1 = slashes.concat(window.location.host);

  // or as you probably should do
  var host2 = location.protocol.concat("//").concat(window.location.host);

  // the above is the same as origin, e.g. "https://stackoverflow.com"
  var host3 = window.location.origin;

  return host1 || host2 || host3;
};
export const stringWithoutSpecialCharacters = (value: string) => {
  // Utiliza una expresión regular para eliminar caracteres especiales excepto letras y espacios
  return value.replace(/[^a-zA-Z\s]/g, "").toLocaleLowerCase();
};

export const getUserValFromKey = (key: string, currentUser: UserModelType) => {
  if (currentUser) {
    const objValues = Object.keys(currentUser);
    let keyValue = objValues.find(
      (f: string) =>
        stringWithoutSpecialCharacters(f) == stringWithoutSpecialCharacters(key)
    );

    if (keyValue) {
      return currentUser[keyValue as keyof UserModelType];
    }
  }

  return "";
};

export const validateNumber = (letter: string) => {
  let validateNumber = false;
  if (Number(letter) || Number(letter) == 0) {
    validateNumber = true;
  }
  return validateNumber;
};

export const validateEmail = (email: String) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const passValidateLoweCase = (password: string) => {
  return password.match(/(?=.*[a-z])/);
};
export const passValidateUpperCase = (password: string) => {
  return password.match(/(?=.*[A-Z])/);
};
export const passValidateNumber = (password: string) => {
  return password.match(/(?=.*\d)/);
};
export const passValidateSpecialCaracter = (password: string) => {
  return password.match(/(?=.*\W)/);
};

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const setCountriesList = (products: CountryListType[]) => {
  if (typeof window == "undefined" || !localStorage) {
    return [];
  }
  localStorage.setItem(COUNTRIES_LIST, JSON.stringify(products));
  return products;
};

export const getContriesList = () => {
  if (typeof window == "undefined" || !localStorage) {
    return [];
  }
  if (!localStorage.getItem(COUNTRIES_LIST)) {
    return [];
  } else {
    const productsList: CountryListType[] = JSON.parse(
      String(localStorage.getItem(COUNTRIES_LIST) || "[]")
    );
    return productsList;
  }
};

export const orderCountryListByCountryCode = (
  data: CountryListType[],
  countryCodes: string[] = []
) => {
  if (Array.isArray(data) && data.length === 0) return data;
  if (!countryCodes) return data;

  const code = countryCodes.at(0);
  if (code && code.toLowerCase() == "de") {
    countryCodes.push("AT");
  }

  const filtered = data.filter((item) =>
    countryCodes.includes(item.countryCode)
  );

  const missing = data.filter(
    (item) => !countryCodes.includes(item.countryCode)
  );

  return [...filtered, ...missing];
};

export const setHotelSelectedB2bPrice = ({
  vid,
  strikePrice,
  strikeTax,
  curreny,
}: {
  vid: number;
  strikePrice?: number;
  strikeTax?: number;
  curreny?: string;
}) => {
  try {
    if (typeof window == "undefined" || !localStorage) {
      return;
    }
    localStorage.setItem(
      "hlt_b2c_selected",
      `${vid}_${String(strikePrice || "")}_${String(
        strikeTax || ""
      )}_${curreny}`
    );
  } catch (error) {}
};
